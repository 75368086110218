import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../components/login/Login";
import { useUser } from "../hooks/UserContext";
import { loginWithGoogle } from "../services/userService";
import Cookies from "js-cookie";

function LoginContainer() {
  const navigate = useNavigate();
  const { user, loading, login } = useUser();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (user) {
      if (user.username) {
        navigate("/promoter");
      } else {
        navigate("/create-profile");
      }
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    setErrors((prevState) => ({
      ...prevState,
      [name]:
        value === ""
          ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
          : "",
    }));
  };

  const handleSubmit = async (formData) => {
    const { email, password } = formData;
    try {
      const result = await login(email, password);
      if (result.success) {
        Cookies.set("gn_userToken", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data));
        if (result.data.username) {
          navigate("/promoter");
          setFormData({
            email: "",
            password: "",
          });
          setMessage("Welcome back.");
          setShowToast(true);
        } else {
          navigate("/create-profile");
        }
      } else {
        setMessage(`Login failed. ${result.message}`);
        setShowToast(true);
      }
    } catch (error) {
      setMessage(`Error signing in user: ${error}`);
      setShowToast(true);
    }
  };

  const handleGoogleLogin = async (userData) => {
    try {
      const result = await loginWithGoogle(userData.data);
      if (result.success) {
        Cookies.set("gn_userToken", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data));
        if (result.data.username) {
          navigate("/promoter");
          setFormData({
            email: "",
            password: "",
          });
          setMessage("Welcome back.");
          setShowToast(true);
        } else {
          navigate("/create-profile");
        }
      } else {
        setMessage(`Login failed. ${result.message}`);
        setShowToast(true);
      }
    } catch (error) {
      setMessage(`Error signing in user: ${error}`);
      setShowToast(true);
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <Login
      onSubmit={handleSubmit}
      loading={loading}
      formData={formData}
      handleChange={handleChange}
      handleInputBlur={handleInputBlur}
      errors={errors}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      googleLogin={handleGoogleLogin}
    />
  );
}

export default LoginContainer;
