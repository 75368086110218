import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import './Vibes.css'
import { getVibesForUser } from '../../services/eventService'
import { Header } from '../common/header/Header'

function Vibes() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  // Check if the current route starts with "/event-goer"
  const [vibes, setVibes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getVibes = async () => {
      if (user && user.userId && user.role) {
        const response = await getVibesForUser(user.userId)

        if (response.success) {
          setVibes(response.data.vibes)
          setLoading(false)
        } else {
          setLoading(false)
        }
      } else {
        console.error('Please sign in or sign up')
        setLoading(false)
      }
    }

    getVibes()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ minHeight: '100vh' }}
      >
        <div className='spinner-grow' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div id='events' className='container pt-4'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <Header />
          <main>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col'>
                  <h3 className='inter-700'>Vibes</h3>
                </div>
              </div>
              {vibes.length === 0 && (
                <div className='center-flex'>You have no vibes.</div>
              )}
              {vibes.length > 0 &&
                vibes.map(vibe => (
                  <div key={vibe.event_id} className='row'>
                    <div className='col'>
                      <Link
                        to={`/promoter/event/${vibe.slug}`}
                        className='text-decoration-none'
                      >
                        <div className='card event border-0'>
                          {vibe.flyer && (
                            <img
                              src={vibe.flyer}
                              className='card-img-top rounded-3'
                              alt='Event'
                            />
                          )}
                          <div className='card-body ps-0 pe-0'>
                            <div className='row'>
                              <div className='col-auto vibe-title'>
                                <img
                                  src='/assets/images/icons/user.png'
                                  alt=''
                                  className='d-inline-block place-marker'
                                />
                                <div className='d-inline-block ms-2'>
                                  <h5
                                    className='card-title epilogue-600 text-truncate'
                                    style={{ maxWidth: '300px' }}
                                  >
                                    {vibe.name}
                                  </h5>

                                  <p className='inter-400 mb-0 location-address'>
                                    <span className='location-name mb-0'>
                                      {vibe.venue_name
                                        ? `${vibe.venue_name}, `
                                        : ''}
                                    </span>{' '}
                                    {vibe.city}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default Vibes
