import React, { useCallback, useRef, useState } from 'react';

import './EventCreate.css';
import Map from '../../common/map/Map';
import AddressAutocomplete from '../../common/address-autocomplete/AddressAutocomplete';
import Toasts from '../../common/toasts/Toasts';
import Editor from '../../common/editor/Editor';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft,faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { generateTimeOptions } from '../../../utils/timeUtils';
import FlyerThumbnails from './FlyerThumbnails';

function EventCreate({
  formData,
  setFormData,
  selectedFile,
  selectedName,
  handleFileChange,
  handleChange,
  handleSubmit,
  handleVenueSelected,
  address,
  disableButton,
  imageLoading,
  errors,
  handleInputBlur,
  setShowToast,
  showToast,
  toastMessage,
  loading,
  generateFlyer,
  images,
  imageURL
}) {
  const quillRef = useRef(null);
  const navigate = useNavigate();
  const timeOptions = generateTimeOptions()

  const handleTextChange = useCallback(() => {
    const quill = quillRef.current;
    const description = quill.root.innerHTML;
    const sanitizedDescription = DOMPurify.sanitize(description);


    setFormData(prevFormData => ({
      ...prevFormData,
      description: sanitizedDescription
    }));
  }, [setFormData]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div id="event-create" className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mb-3 d-block text-black mt-3"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
            </div>
            {/* Question Mark that should link to FAQ or Contact Us Page */}
            <div>
              <FontAwesomeIcon 
                icon={faCircleQuestion} 
                className=""
                onClick={() => navigate(-1)}/>
                </div>
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <h3 className="inter-700 mt-0">Basic Info</h3>
            <p>
              Name your event and tell event - goers, why they should come.Add
              details that highlight what makes it unique
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label d-none">
                  Event Title *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  placeholder="Event Title*"
                  onChange={handleChange}
                  required
                  onBlur={handleInputBlur}
                />
                <div className="form-text text-danger">
                  {errors?.name}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label d-none">
                  Event Description
                </label>
                <Editor
                  ref={quillRef}
                  defaultValue={formData.description}
                  onTextChange={handleTextChange}
                />
                {errors.description && <span className="error">{errors.description}</span>}

                <input
                  type="hidden"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />

                {/*
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  rows="3"
                  value={formData.description}
                  placeholder="Event Description"
                  onChange={handleChange}
                ></textarea>
                */}
              </div>
              <div className="mb-3">
                <label htmlFor="category" className="form-label">
                  Category
                </label>
                <select
                  className="form-select"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                >
                  <option value="">Category</option>
                  <option value="Music">Music</option>
                  <option value="Nightlife">Nightlife</option>
                  <option value="Holidays">Holidays</option>
                  <option value="Health">Health</option>
                  <option value="Hobbies">Hobbies</option>
                  <option value="Business">Business</option>
                  <option value="Food & Drink">Food &amp; Drink</option>
                  <option value="Performing and Visual Arts">
                    Performing and Visual Arts
                  </option>
                  {/* Add category options here */}
                </select>
                <div className="form-text text-danger">
                  {errors?.category}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  className="form-select"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                >
                  <option value="1">Public</option>
                  <option value="0">Private</option>
                </select>
                <div className="form-text text-danger">
                  {errors?.type}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="tags" className="form-label">
                  Tags
                </label>
                <p>
                  Improve discoverability of your event by adding tags relevant to
                  the subject matter.
                </p>
                <input
                  type="text"
                  className="form-control"
                  id="tags"
                  name="tags"
                  value={formData.tags}
                  placeholder="Add tags"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                />
                <div className="form-text text-danger">
                  {errors?.tags}
                </div>
                <div className="form-text">
                  Enter tags separated by comma. e.g., fun, holiday, party
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="ticketLink"
                  className="form-label">
                  Ticket Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ticketLink"
                  name="ticketLink"
                  value={formData.ticketLink}
                  placeholder="Add ticket link"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                />
              </div>
              <h3 className="inter-700">Venue</h3>
              <p>
                Help peoples in the area discover your event and let attendees
                know where to show up.
              </p>
              <div className="mb-3">
                <label htmlFor="venueLocation" className="form-label d-none">
                  Venue Location
                </label>
                <h5>Venue Name</h5>
                {address && <Map address={address} />}
              </div>
              <div className="mb-3">
                <AddressAutocomplete onPlaceSelected={handleVenueSelected} value={formData.venueName} />
              </div>
              <h6>Street Address</h6>
              <div className="mb-3">
                <label htmlFor="street" className="form-label d-none">
                  Street *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="street"
                  name="street"
                  value={formData.street}
                  placeholder="Street *"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                />
                <div className="form-text text-danger">
                  {errors?.street}
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="city" className="form-label d-none">
                    City *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formData.city}
                    placeholder="City*"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  />
                  <div className="form-text text-danger">
                    {errors?.city}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="state" className="form-label d-none">
                    State/Province *
                  </label>
                  <select
                    className="form-select"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  >
                    <option value="">Select a state</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <div className="form-text text-danger">
                    {errors?.state}
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="zip" className="form-label d-none">
                    Postal Code *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    value={formData.zip}
                    placeholder="Postal Code*"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  />
                  <div className="form-text text-danger">
                    {errors?.zip}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="country" className="form-label d-none">
                  Country *
                </label>
                <select
                  className="form-select"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  disabled
                >
                  <option value="USA">United States</option>
                </select>
              </div>
              <h3 className="inter-700">Date and time</h3>
              <p>
                Tell event-goers when your event starts and ends so they can
                make plans to attend.
              </p>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="startDate" className="form-label">
                    Start Date
                  </label>
                  <input type="date"
                    className="form-control"
                    id="startDate"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="MM/DD/YYYY"
                    aria-describedby="validationStartDateFeedback"
                    required />
                  <div id="validationStartDateFeedback"
                    className="text-danger">
                    {errors?.startDate}
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="startDate" className="form-label">
                    End Date
                  </label>
                  <input type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="MM/DD/YYYY"
                    required
                    aria-describedby="validationEndDateFeedback"
                    disabled={!formData?.startDate} />
                  <div id="validationEndDateFeedback" className="text-danger">
                    {errors?.endDate}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="start" className="form-label">Start Time</label>
                  <select
                    className="form-control"
                    id="start"
                    name="start"
                    value={formData.start}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                    aria-describedby="validationStartFeedback"
                    disabled={!formData?.endDate}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <div id="validationStartFeedback" className="text-danger">
                    {errors?.startTime}
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="end" className="form-label">End Time</label>
                  <select
                    className="form-control"
                    id="end"
                    name="end"
                    value={formData.end}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                    aria-describedby="validationEndFeedback"
                    disabled={!formData?.start}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <div id="validationStartFeedback" className="text-danger">
                    {errors?.endTime}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="model" className="form-label">
                  Model
                </label>
                <p>Who do you want to be featured in your autogenerated flyer</p>
                <div className="form-check form-group">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="model-man"
                      name="model"
                      value="man"
                      checked={formData.model === 'man'}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                    />
                    <label className="form-check-label" htmlFor="model-man">Man</label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="model-woman"
                      name="model"
                      value="woman"
                      checked={formData.model === 'woman'}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                    />
                    <label className="form-check-label" htmlFor="model-woman">Woman</label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="model-couple"
                      name="model"
                      value="couple"
                      checked={formData.model === 'couple'}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                      reqired
                    />
                    <label className="form-check-label" htmlFor="model-couple">Couple</label>
                  </div>

                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="model-group"
                      name="model"
                      value="group"
                      checked={formData.model === 'group'}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                    />
                    <label className="form-check-label" htmlFor="model-group">Group</label>
                  </div>
                </div>
                <div className="form-text text-danger">
                  {errors?.model}
                </div>
              </div>

              <button
                type="button"
                className="btn"
                id="flyer-button"
                style={{ marginTop: '20px' }}
                onClick={generateFlyer}
                disabled={disableButton}
              >
                Generate Flyer With AI
              </button>

              {images && images.length > 0 && (
                <FlyerThumbnails images={images} handleFileChange={handleFileChange} />
              )}

              <h3 className="inter-700 mb-0 mt-5">Event Image</h3>

              <div>
                <label htmlFor="" className="form-label">
                  Select One Of The Above Images or Upload Hero Photo
                </label>

                <FileUploader
                  selectedName={selectedName}
                  onFileSelect={handleFileChange}
                  imageLoading={imageLoading}
                />
              </div>

              {images.length === 0 && imageURL && (
                <>
                  <div className="full-image-container" style={{ marginTop: '20px' }}>
                    <img
                      src={imageURL}
                      alt="Full size"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                </>
              )}

              <button
                type="submit"
                className="btn"
                id="continue"
                style={{ marginTop: '20px' }}
                disabled={disableButton}
              >
                Continue
              </button>
            </form>
          </main>
        </div>
      </div>
    </div>
  );
}

const FileUploader = ({ onFileSelect, selectedName, imageLoading }) => {
  const [dragOver, setDragOver] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  const [dragOverCounter, setDragOverCounter] = useState(0);
  const fileInputRef = useRef(null);

  const handleDragEnter = useCallback((e) => {

    e.preventDefault();
    e.stopPropagation();
    setDragOverCounter((prev) => prev + 1);
  }, []);

  const handleDragLeave = useCallback(
    (e) => {
      console.log('handleDragLeave: ');

      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);

      setDragOverCounter((prev) => prev - 1);
      if (dragOverCounter <= 1) {
        // When counter goes back to 0, it means we have truly left the drop zone
        setDragOverCounter(0);
      }
    },
    [dragOverCounter]
  );

  const handleDragOver = useCallback((e) => {
    setDragOver(true);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onFileSelect(e.dataTransfer.files[0]);
        // Generate thumbnail from dropped image
        generateThumbnail(e.dataTransfer.files[0]);
      }
    },
    [onFileSelect]
  );

  const handleChange = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        onFileSelect(e.target.files[0]);
        // Generate thumbnail from selected image
        generateThumbnail(e.target.files[0]);
      }
    },
    [onFileSelect]
  );

  const triggerFileInputClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const generateThumbnail = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setThumbnail(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      className={`file-upload ${dragOver ? 'drag-over' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={triggerFileInputClick}
    >
      {imageLoading ? (
        <div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Uploading...</span>
            </div>
          </div>
          <div className="uploading-text">Uploading...</div>
        </div>
      ) : (
        <>
          {thumbnail ? (
            <img src={thumbnail} alt="thumbnail" className="thumbnail" />
          ) : (
            <img src="/assets/images/icons/file.svg" alt="upload" />
          )}
          <h3>
            {' '}
            {selectedName ||
              'Drag and drop a file or click here to select a file'}
          </h3>
          <p>JPEG or PNG, no larger than 10MB. Recommended size is 500x500.</p>
        </>
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};
export default EventCreate;
