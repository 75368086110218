import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircleQuestion, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export function Header () {

    const navigate = useNavigate();

    return(
    <header>
            <div className="row mb-5">
              <div className="d-flex justify-content-around align-items-center">
                <img src="/assets/images/logo.svg" alt="GreatNight Logo" id="logo" />
                <FontAwesomeIcon 
                  icon={faBell} 
                  className="visually-hidden" />
                {/* Question Mark that should link to FAQ or Contact Us Page */}
                <FontAwesomeIcon 
                  icon={faCircleQuestion}
                  onClick={() => navigate("/promoter/event/vibes/")}
                />
                <FontAwesomeIcon
                  icon={faCamera}
                  onClick={()=>navigate("/promoter/my-event")}
                />
                <FontAwesomeIcon
                  icon={faUser}
                  onClick={()=>navigate("/promoter/profile")}
                />
              </div>
            </div>
          </header>
    )   
}