import React from 'react';
import './TicketDetails.css';
import { formatEventTime } from '../../../services/eventService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function TicketDetails({ user, isRegistered, ticketData, error, loading }) {
  const navigate = useNavigate();
  console.log('ticketDetails:', { user, isRegistered, ticketData, error, loading });

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div id="ticket-details"
      className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div className="row mt-5 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <img src="/assets/images/logo.svg" alt="GreatNight Logo" id="logo" />

                <div className="d-flex align-items-center justify-content-end">
                  <FontAwesomeIcon icon={faBell} className="visually-hidden" />
                </div>
              </div>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="mb-3 d-block text-black mt-3"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(-1)}
              />

              {isRegistered && (
                <div id="success-msg">
                  <h2 className="mt-4 mb-1 text-center epilogue-400">Here Is Your QR Code!</h2>
                  <h3 className="mb-0 text-center epilogue-400">
                    {ticketData?.event?.name}
                  </h3>
                </div>
              )}
            </div>

          </header>
          <main className="ps-5 pe-5">
            <div className="mb-3 text-center">
              <img src={ticketData?.ticket} alt={ticketData?.event?.name} id="ticket-qr-code" className="img-thumbnail p-0 w-100" />
            </div>

            <h2 className="mt-4 mb-1 inter-700">Event Details</h2>
            <p className="mb-2 inter-400 date-time">
              {formatEventTime(ticketData?.event?.start, ticketData?.event?.end)}
            </p>
            <h3 className="mb-0 mt-4 inter-700">
              {ticketData?.event?.venue_name}
            </h3>
            <p className="mb-2 inter-300 location">
              {ticketData?.event?.street}, {ticketData?.event?.city}, {ticketData?.event?.state}, {ticketData?.event?.country}
            </p>
            <p className="mb-4 mt-4 inter-300" dangerouslySetInnerHTML={{ __html: ticketData?.event?.description }}></p>
          </main>
        </div>
      </div>
    </div>
  );
}

export default TicketDetails;
