import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './MyEvents.css';
import { getEventsForUser } from '../../../../services/eventService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { getTickets } from '../../../../services/ticketService';
import { format, parseISO } from 'date-fns';
import { Header } from '../../../common/header/Header';

function MyEvents() {
  const location = useLocation();
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  // Check if the current route starts with "/event-goer"
  const isPromoterRoute = location.pathname.startsWith('/promoter');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const userId = user?.userId;
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState('upcoming');
  const [tickets, setTickets] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null)

  useEffect(() => {
    const getEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getEventsForUser(user.userId);

        if (response.success) {
          setEvents(response.data.events)
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        console.error('Please sign in or sign up')
        setLoading(false);
      }
    }

    const fetchTickets = async () => {
      try {
        setLoading(true);
        const ticketsData = await getTickets(activeTab, userId);
        setTickets(ticketsData.tickets);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch tickets. Please try again.');
        setLoading(false);
      }
    };

    getEvents();
    fetchTickets();
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>

      <div id="events"
        className="container pt-4">
        <div className="row justify-content-center">
          <div className="col-lg-6">
           <Header />
            <main>
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    {isPromoterRoute && (<h3 className="inter-700">My Events</h3>)}
                  </div>
                  {isPromoterRoute && (
                    <div className="col text-end">
                      <Link to="create"
                        id="add-event">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                          <path
                            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                      </Link>
                    </div>
                  )}

                </div>
                <div className="row">
                  <div className="col">
                    <p>Here’s the list of events that you are attending. When you arrive, open the QR code and find other GreatNighters to scan you so you can earn points!</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h2 className="tickets-title">
                      Events You’re Attending
                    </h2>
                  </div>
                </div>
                {isPromoterRoute && events.length === 0 &&
                  <div className="center-flex">
                    You have no events.
                    <br />
                    Click the "+" button to create one.
                  </div>
                }
                {/* {tags && tags.length > 0 &&
                  <div className="">
                    {tags.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => filterEventsByTag(tag)}
                        style={{
                          cursor: 'pointer',
                          marginRight: '10px',
                          color: selectedTag === tag ? 'black' : 'gray', // Black if selected, gray if not
                          fontWeight: selectedTag === tag ? 'bold' : 'normal', // Bold if selected
                          textDecoration: selectedTag === tag ? 'underline' : 'none' // Underline if selected
                        }}
                      >#{tag} </span>
                    ))}
                  </div>
                } */}
                {events.length > 0 && events.map((event) => (
                  <div key={event.event_id} className="row">
                    <div className="col">
                      {isPromoterRoute && (
                        <Link to={`${event.slug}`}
                          className="text-decoration-none">
                          <div className="card event border-0">
                            {event.flyer && (<img src={event.flyer}
                              className="card-img-top rounded-3"
                              alt="Event" />)}
                            <div className="card-body ps-0 pe-0">
                              <div className="row">
                                <div className="col-auto">
                                  <img src="/assets/images/icons/user.png"
                                    alt=""
                                    className="d-inline-block place-marker" />
                                  <div className="d-inline-block ms-2">
                                    <h5 className="card-title epilogue-600 text-truncate" style={{ maxWidth: '300px' }}>
                                      {event.name}
                                    </h5>

                                    <p className="inter-400 mb-0 location-address">
                                      <span className="location-name mb-0">
                                        {event.venue_name ? `${event.venue_name}, ` : ''}
                                      </span> {event.city}
                                    </p>
                                  </div>
                                </div>
                                <div className="col text-end">
                                  <FontAwesomeIcon icon={faEllipsisVertical} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="container mt-4">
                <div className="row">
                  <div className="col">
                    <h2 className="tickets-title">
                      Events You’re Attending
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {tickets.map((ticket, index) => (
                      <Link
                        to={`${ticket.rsvp_id}`}
                        key={index}
                        className="ticket text-decoration-none"
                      >
                        <div className="ticket-image">
                          <img
                            src={ticket.flyer}
                            alt={ticket.name}
                            className="shadow"
                          />
                        </div>
                        <div className="ticket-details text-center w-100">
                          <h3>{ticket.name}</h3>
                          <p>
                            {ticket?.start && formatDate(parseISO(ticket.start))}
                          </p>
                          <Link to={`${ticket.rsvp_id}`} key={index} className="inter-400">View QR Code</Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

    </>
  );
}

const formatDate = (date) => {
  const day = format(date, "d");
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // catch all numbers between 4 and 20
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  const formattedDate = format(date, "MMMM d");
  const formattedTime = format(date, "h:mma").toLowerCase();
  return `${formattedDate}${daySuffix(day)}. ${formattedTime}`;
};

export default MyEvents;
