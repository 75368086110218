import api from './api'

const createUser = async formData => {
  try {
    const response = await api.post('/user/register', formData)
    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        data: response.data,
        message: 'User registered successfully'
      }
    } else {
      const errorMessage = response.data.message || 'Failed to register user'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    console.error('Error creating user:', error)
    return { success: false, message: error.response.data.message }
  }
}

const createGuestUser = async formData => {
  try {
    const response = await api.post('/user/register-guest', formData)
    if (response.status === 200 || response.status === 201) {
      return {
        success: true,
        data: response.data,
        message: response.data.message || 'User registered successfully'
      }
    } else {
      const errorMessage =
        response.data.message || 'Failed to register guest user'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    console.error('Error creating guest user:', error)
    return { success: false, message: error.response.data.message }
  }
}

const loginUser = async (email, password) => {
  try {
    const response = await api.post('/user/login', { email, password })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: 'Login successful'
      }
    } else {
      const errorMessage = response.data.message || 'Failed to login'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const loginWithGoogle = async userData => {
  console.log('userData in loginWithGoogle', userData)
  try {
    const body = {
      name: userData.name,
      email: userData.email
    }

    const response = await api.post('/user/login/google', body)

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: 'Login successful'
      }
    } else {
      const errorMessage = response.data.message || 'Failed to login'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const validateUser = async token => {
  try {
    const response = await api.post('/user/validate-user', {
      headers: {
        Authorization: token
      }
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: response.data.message || 'User validated successfully'
      }
    } else {
      const errorMessage = response.data.message || 'Failed to validate user'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const forgotPassword = async email => {
  try {
    const response = await api.post('/user/forgot-password', { email })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to forgot password'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const resetPassword = async (email, otp, newPassword) => {
  try {
    const response = await api.post('/user/reset-password', {
      email,
      otp,
      newPassword
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to reset password'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const changePassword = async (email, currentPassword, newPassword) => {
  try {
    const response = await api.post('/user/change-password', {
      email,
      currentPassword,
      newPassword
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to change password'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const createProfile = async data => {
  try {
    const res = await api.post('/user/profile', data)
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
        message: 'Profile created successfully'
      }
    }
    return res.data
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const getUserStatus = async (token, userId) => {
  try {
    const response = await api.get(`/user/status/${userId}`, {
      headers: {
        Authorization: token
      }
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to get user status'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const followUser = async (token, userId, followerId) => {
  try {
    const response = await api.post('/user/follow', { userId, followerId }, {
      headers: {
        Authorization: token
      }
    })

    if (response.status === 200) {
      return {
        success: true,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to follow user'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const unfollowUser = async (token, userId, followerId) => {
  try {
    const response = await api.post('/user/unfollow', { userId, followerId }, {
      headers: {
        Authorization: token
      }
    })

    if (response.status === 200) {
      return {
        success: true,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to unfollow user'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

const getUserFollowStats = async (token, userId) => {
  try {
    const response = await api.get(`/user/follow-stats/${userId}`, {
      headers: {
        Authorization: token
      }
    })

    if (response.status === 200) {
      return {
        success: true,
        data: response.data,
        message: response.data.message
      }
    } else {
      const errorMessage = response.data.message || 'Failed to get user follow stats'

      return { success: false, message: errorMessage }
    }
  } catch (error) {
    return { success: false, message: error.response.data.message }
  }
}

export {
  createUser,
  createProfile,
  createGuestUser,
  loginUser,
  validateUser,
  forgotPassword,
  resetPassword,
  changePassword,
  loginWithGoogle,
  getUserStatus,
  followUser,
  unfollowUser,
  getUserFollowStats
}
