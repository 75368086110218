import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { loginUser, validateUser } from '../services/userService'

const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const userToken = Cookies.get('gn_userToken')

    const autoLogin = async () => {
      if (userToken) {
        try {
          const result = await validateUser(userToken)

          if (result.success) {
            Cookies.set('gn_userToken', userToken)
            localStorage.setItem('user', JSON.stringify(result.data))
            setUser(result.data)
          } else {
            handleLogout()
          }
        } catch (error) {
          console.error('Error auto-login:', error)
          handleLogout()
        } finally {
          // Set loading to false after auto-login process
          setLoading(false)
        }
      } else {
        handleLogout()
        // Set loading to false if no user token
        setLoading(false)
      }
    }

    autoLogin()

    const isSharedLink = location.pathname.includes('influencer')
    const isForgotPasswordLink = location.pathname.endsWith('forgot-password')
    const isResetPasswordLink = location.pathname.endsWith('reset-password')
    const isRegistrationLink = location.pathname.endsWith('register')
    const isPrivacyPolicyLink = location.pathname.endsWith('privacy-policy')
    const isTermsOfServiceLink = location.pathname.endsWith('terms-of-service')
    const isScanCallbackLink = location.pathname.includes('scan-qr-callback')

    if (
      !isSharedLink &&
      !userToken &&
      !isForgotPasswordLink &&
      !isResetPasswordLink &&
      !isRegistrationLink &&
      !isPrivacyPolicyLink &&
      !isTermsOfServiceLink &&
      !isScanCallbackLink
    ) {
      navigate('/')
    }
  }, [location.pathname, navigate])

  const login = async (email, password) => {
    try {
      const result = await loginUser(email, password)
      if (result.success) {
        Cookies.set('gn_userToken', result.data.token)
        localStorage.setItem('user', JSON.stringify(result.data))
        setUser(result.data)
        return { success: true }
      } else {
        return { success: false, message: result.message }
      }
    } catch (error) {
      console.error('Error logging in:', error)
      return { success: false, message: 'Error logging in' }
    }
  }

  const handleLogout = () => {
    Cookies.remove('gn_userToken')
    localStorage.removeItem('user')
    setUser(null)
  }

  const logout = async () => {
    setLoading(true)
    handleLogout()
    window.location.replace('/')
  }

  return (
    <UserContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </UserContext.Provider>
  )
}
